@font-face {
  font-family: IvarTextHydro;
  src: url('../src/fonts/Ivar/IvarTextHydro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: IvarTextHydro;
  src: url('../src/fonts/Ivar/IvarTextHydro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: IvarTextHydro;
  src: url('../src/fonts/Ivar/IvarTextHydro-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: IvarTextHydro;
  src: url('../src/fonts/Ivar/IvarTextHydro-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: IvarDisplayHydro;
  src: url('../src/fonts/Ivar/IvarDisplayHydro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --hydro-blue: #444d55;
  --hydro-blue-light: #648095;
  --hydro-blue-gray: #4a4a4a;
  --hydro-light-blue: #768692;
  --hydro-aluminium: #8c8c8c;
  --hydro-green: #43807a;
  --hydro-warm: #c5b9ac;
  --hydro-purple: #4a3041;
  --hydro-bauxite: #b95946;
  --hydro-web-dark-grey: #4a4a4a;
  --hydro-web-mid-grey: #757575;
  --hydro-web-gray: #d8d8d8;
  --hydro-web-light: #f4f4f4;
  --hydro-web-white-toned: #f8f8f8;
  --hydro-white: #fff;
  --hydro-black: #000;
  --hydro-web-black-grey: #3a3a3a;
  --hydro-gray: #e3e2e2;
  --hydro-gray-light: #9b9b9b;
}

* {
  font-family: Arial, Helvetica, sans-serif;
}

body {
  background-color: var(--hydro-web-white-toned) !important;
}

.sidebar-item {
  color: var(--hydro-blue) !important;
  font-size: 1rem !important;
  line-height: 1.3;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.sidebar-item:hover:not(.sidebar-item[data-selected='true']) {
  background-color: var(--hydro-gray) !important;
}

.sidebar-item[data-selected='true'] {
  color: white !important;
}

.sidebar-item svg {
  color: var(--hydro-bauxite);
  width: 0.875rem;
  height: 0.875rem;
}

.sidebar-header a,
.sidebar-subheading button {
  font-family: IvarDisplayHydro, 'Times New Roman', times, baskerville, georgia,
    serif;
  letter-spacing: 0.0187rem;
}

.sidebar-header a {
  color: var(--hydro-blue);
  font-size: 1.5rem;
}

.sidebar-header img {
  height: 2.5rem;
  margin: 0.5rem 0.5rem 1rem;
  width: auto;
}

.sidebar-subheading {
  margin-bottom: 0.25rem;
}

.sidebar-subheading button {
  color: var(--hydro-blue-light);
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: none;
  line-height: 1.5;
}

.sidebar-subheading button span:first-of-type {
  margin-top: 1rem;
}

.sidebar-subheading-action {
  margin-top: 0.35rem !important;
}

button {
  line-height: 1.25;
}

#meta,
#meta + * {
  display: none !important;
}
